import * as React from 'react';
import { cva } from 'class-variance-authority';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { agate as dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { ScrollArea, ScrollBar } from './UI/ScrollArea/Scrollbar';
import { cn } from '../../utils/common';
import { RcSelect } from './UI/Select';

type ProvidersTypes = {
   label?: string
   value?: string
   data?: {
      original: any,
      transformed: any
   }
}

type ProviderRespViewerProps = {
   title?: string
   description?: string
   providers?: ProvidersTypes[]
}

const rootCls = cva('w-full grid grid-cols-1 lg:grid-cols-2 h-[900px] xxl:px-[7rem] scale-[.8] -mt-[2rem]');
const leftCls = cva(
   cn(
      'rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg overflow-hidden bg-white',
      'flex flex-col gap-5 p-10'
   )
);

const bannerCls = cva(
   cn('p-3 sticky top-0  border-t-[10px] text-md font-semibold rounded-r-lg')
)

export const ProviderRespViewer = React.forwardRef<
   HTMLDivElement, React.HTMLProps<HTMLDivElement> &
   ProviderRespViewerProps>((props, ref) => {
      const {
         className,
         title,
         description,
         providers: providersList = []
      } = props;

      const [selected, setSelected] = React.useState<ProvidersTypes | undefined>(
         providersList?.length ? providersList[0] : undefined
      );

      const onChange = (arg) => {
         setSelected(arg)
      }

      return (
         <div
            className={rootCls({ className })}
            ref={ref}
         >
            <div className={leftCls()}>
               <div className='flex flex-col gap-2 '>
                  <span className='text-gray-900 text-lg font-bold'>{title}</span>
                  {description && (
                     <span className='text-sm font-medium text-gray-700'>{description}</span>
                  )}
               </div>
               <div className='flex flex-col md:flex-row md:justify-between gap-5 md:items-end'>
                  <div className='flex flex-col gap-2'>
                     <span className='text-gray-900 text-md font-bold'>Raw Data</span>
                     <span className='text-sm font-medium text-gray-700'>Choose the source system.</span>
                  </div>
                  <RcSelect
                     onChange={onChange}
                     options={providersList}
                     className='min-w-[240px]'
                     value={selected}
                  />
               </div>
               <CodeBlock
                  json={selected?.data?.original ?? {}}
                  banner={false}
                  scrollContainerProps={{ className: 'w-auto' }}
               />
            </div>
            <CodeBlock
               json={selected?.data?.transformed ?? {}}
               scrollContainerProps={{ className: 'rounded-b-lg lg:rounded-r-lg w-auto' }}
            />
         </div>
      )
   });

type CodeBlockProps = {
   json: { [key: string]: any }
   language?: string
   className?: string
   style?: React.CSSProperties
   scrollContainerProps?: ScrollContainerTypes
   banner?: boolean
}

const CodeBlock = (props: CodeBlockProps) => {
   const { json = {}, language = 'json', banner = true, scrollContainerProps } = props;
   const ref = React.useRef<HTMLDivElement>(null);

   const [conStyle, setConStyle] = React.useState<React.CSSProperties>({})

   React.useEffect(() => {
      const preTag = document.getElementsByTagName('pre');
      if (preTag && preTag.length >= 1) {
         const [container] = preTag,
            { backgroundColor } = window.getComputedStyle(container);
         setConStyle({ backgroundColor });
      }
   }, [])

   return (
      <ScrollContainer
         style={{ ...conStyle }}
         {...scrollContainerProps}
      >
         <div className='' >
            {banner && (
               <div
                  className={bannerCls()}
                  style={{ background: 'rgb(44 75 108)' }}
               >
                  <span className='text-white'>Unizo unified API schema</span>
               </div>
            )}
            <SyntaxHighlighter language={language} customStyle={{ background: 'rgb(39 61 84)' }} style={dark} ref={ref as any}>
               {JSON.stringify(json, null, 2)}
            </SyntaxHighlighter>
         </div>
      </ScrollContainer>
   )
};

type ScrollContainerTypes = {
   children?: React.ReactElement
   height?: number
   style?: React.CSSProperties
   className?: string
}

const scrollCls = cva(
   'cursor-grabbing',
   {
      variants: {
         orientation: {
            vertical: '!right-[5px] !top-[5px] z-20',
            horizontal: '!bottom-[5px] !left-[5px]'
         }
      }
   }
)

const ScrollContainer = (props: ScrollContainerTypes) => {
   const { children, height = '100', style, className: classNameProp } = props;


   const classNames = React.useMemo(() => {
      return `h-[${height}%]`;
   }, [height]);


   const orientations = ['vertical', 'horizontal'];
   return (
      <ScrollArea type='hover'
         rootClassName={cn(classNameProp, classNames)}
         rootStyle={style}
      >
         {children}
         {orientations.map((orientation: any, index) => (
            <ScrollBar orientation={orientation} className={scrollCls({ orientation })} key={index} />
         ))}
      </ScrollArea>
   )
}