import { SectionContainer, ProSectionContainer, Link, GradientBanner, ProCard, MediaInfoCard, CardOrientationContainer, CommunitySection, SegmentedContainer } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProSectionContainer,
  Link,
  GradientBanner,
  ProCard,
  MediaInfoCard,
  CardOrientationContainer,
  CommunitySection,
  SegmentedContainer,
  React
};