import { SectionContainer, ProSectionContainer, CardOrientationContainer, MediaInfoCard, GradientBanner, ProCard, Link, BsIcons, CommunitySection } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProSectionContainer,
  CardOrientationContainer,
  MediaInfoCard,
  GradientBanner,
  ProCard,
  Link,
  BsIcons,
  CommunitySection,
  React
};