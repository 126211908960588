import React from 'react';

import { CaseStudies as CaseComp } from '../../../../src/components/common/CaseStudies'


export const CaseStudies = () => {
   return (
      <>
         <CaseComp
            items={[
               {
                  name: 'Source Code',
                  description: 'Effortlessly integrate and manage your source code repositories with our dependable framework and tools. ',
                  link: {
                     href: '/verticals/source-code'
                  },
                  image: {
                     src: '/images/sourcecode-services.svg'
                  }
               },
               {
                  name: 'Ticketing',
                  description: 'Simplify your ticketing workflows with our effective and unified framework and tools. ',
                  link: {
                     href: '/ticketing'
                  },
                  image: {
                     src: '/images/ticket-services.svg'
                  }
               },
               {
                  name: 'Packages and Container Registries',
                  description: 'Enhance your package and container management with our sophisticated framework and service tools.',
                  image: {
                     src: '/images/pcr-services.svg'
                  }
               },
               {
                  name: 'Communications',
                  description: 'Strengthen your communication processes with our reliable and secure framework and toolset solutions.',
                  image: {
                     src: '/images/comms-service.svg'
                  }
               },
               {
                  name: 'Incident Management',
                  description: 'Seamlessly handle incidents with our all-inclusive and unified framework and service tools.',
                  image: {
                     src: '/images/incident-services.svg'
                  }
               },
               {
                  name: 'SIEM',
                  description: 'Elevate your security information and event management with our robust framework and solution tools.',
                  image: {
                     src: '/images/siem-service.svg'
                  }
               },
               {
                  name: 'Vulnerability Management',
                  description: 'Actively address vulnerabilities with our cutting-edge framework and service tools.',
                  image: {
                     src: '/images/vulnerability-services.svg'
                  }
               },
               {
                  name: 'EDR & XDR',
                  description: 'Enhance your endpoint detection and response capabilities with our specialized framework solutions.',
                  image: {
                     src: '/images/edr-services.svg'
                  }
               },
               {
                  name: 'File storage & Content management',
                  description: 'Streamline your file storage and content management with our intuitive and effective framework solutions.',
                  image: {
                     src: '/images/filestorage-services.svg'
                  }
               }
            ]}
         />
      </>
   )
}