import { cva } from 'class-variance-authority';
import React, { HTMLProps } from 'react';
import { Link } from 'gatsby';
import { cn } from '../../../utils/common';

const rootCls = cva(
    cn('grid grid-cols-4')
);

const cardItemCls = cva(
    cn(
        'flex-1 group shadow-lg w-full flex flex-col  border border-gray-300 rounded-2xl'
    )
)

type ItemTypes = {
    name?: string
    image?: HTMLProps<HTMLImageElement>
    description?: string
    link?: HTMLProps<HTMLAnchorElement>
}

type CardProps = {
    item: ItemTypes
}

type CaseStudiesProps = {
    items?: ItemTypes[]
}

export const CaseStudies = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & CaseStudiesProps>(
    (props, ref) => {

        const {
            className,
            items = [],
            ...rest
        } = props;

        return (
            <section className="py-24 " {...rest} ref={ref}>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h2 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-16">
                        Case Studies
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-8 flex-wrap md:flex-wrap lg:flex-nowrap lg:gap-y-10 gap-5 lg:justify-between lg:gap-x-8">
                        {items.map((item, key) => {
                            return <Card key={key} item={item} />
                        })}
                    </div>
                </div>
            </section>

        )
    })

export const Card = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & CardProps>((props, ref) => {

    const {
        className,
        item,
        ...rest
    } = props;

    const { name, description, link, image } = item

    return (
        <Link
            to={link?.href ?? '/'}
            className='flex flex-col'
        >
            <figure
                className={cardItemCls({
                    className
                })}
                {...rest}
                ref={ref}
            >
                <div className="flex items-center">
                    <img
                        alt="blogs tailwind section"
                        className="rounded-t-2xl w-full"
                        {...image}
                    />
                </div>
                <div className="p-4 lg:p-4 flex flex-col flex-1 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-100">
                    <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                        {name}
                    </h4>
                    <p className="text-gray-500 flex-1">
                        {description}
                    </p>
                    <a
                        {...link}
                        className="cursor-pointer text-lg primary-text-200 font-semibold"
                    >
                         Read more → 
                    </a>
                </div>

            </figure >
        </Link>
    )
})


