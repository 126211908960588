import { SectionContainer, Link, ProSectionContainer, CommunitySection, ProCard, MediaInfoCard, GradientBanner, CardOrientationContainer } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  Link,
  ProSectionContainer,
  CommunitySection,
  ProCard,
  MediaInfoCard,
  GradientBanner,
  CardOrientationContainer,
  React
};