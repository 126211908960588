import { cva } from "class-variance-authority";
import React, { LegacyRef, cloneElement, useCallback, useMemo } from "react";
import ReactSelect, { ActionMeta, MultiValue, PropsValue, SingleValue, components } from "react-select";
import { cn } from "../../../../utils/common";

type RcSelectProps = {
   options: { [key: string]: any }[]
   className?: string
   isMulti?: boolean
   onChange?: ((newValue: SingleValue<{
      [key: string]: any;
   }> | MultiValue<{
      [key: string]: any;
   }>, actionMeta: ActionMeta<{
      [key: string]: any;
   }>) => void) | undefined
   value?: PropsValue<{
      [key: string]: any;
   }> | undefined
}

const rootCls = cva(
   cn('min-w-[240px]')
)

const optionCls = cva(
   cn('w-full h-full flex flex-row items-center justify-start gap-2 font-medium hover:bg-gray-100 cursor-pointer active:bg-gray-200', 'p-2'),
   {
      variants: {
         isSelected: {
            true: '!bg-primary-100/90  !text-white',
            false: 'text-gray-900 bg-white '
         }
      }
   }
);

const iconCls = cva(
   cn('h-7 w-7')
)

const { Option, ValueContainer } = components;

const IconOption = (props) => {
   const { label, icon } = props?.data;

   return (
      <Option  {...props}>
         <div className={optionCls({ isSelected: props.isSelected })}>
            {icon && cloneElement(icon, { className: iconCls() })}
            {label}
         </div>
      </Option>
   )
};

const ValueIndicator = ({ children, ...props }: any) => {
   const { isMulti } = useMemo(() => props.selectProps, [props.selectProps]);

   const value = useMemo(() => {
      const entities = props.getValue();
      if (isMulti) return entities;
      if (!Array.isArray(entities) && !entities.length) {
         return entities;
      }
      return entities[0];
   }, [props.getValue(), isMulti]);

   const renderIcon = useCallback(() => {
      if (isMulti || !value || !value.icon) return null;
      return cloneElement(value.icon, { className: 'h-5 w-5 text-black' })
   }, [value, isMulti])

   return (
      <ValueContainer  {...props}>
         <div className="flex flex-row items-center justify-start gap-1 h-full w-full">
            {renderIcon()}
            {children}
         </div>
      </ValueContainer>
   );
};

const styles = {
   option: base => ({
      ...base,
      padding: 0
   })
}

export const RcSelect = React.forwardRef((props: RcSelectProps, ref: LegacyRef<any>) => {
   const { className, options, isMulti = false, onChange, value } = props;

   return (
      <ReactSelect
         options={options ?? []}
         className={rootCls({ className })}
         components={{ Option: IconOption, ValueContainer: ValueIndicator }}
         ref={ref}
         isMulti={isMulti}
         styles={styles}
         onChange={onChange}
         value={value}
      />
   )
})