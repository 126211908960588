import { SectionContainer, ProviderRespViewer, Link, BsIcons, BoxIcons, OctIcons, MediaInfoCard, CommunitySection, ProSectionContainer, ProCard } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProviderRespViewer,
  Link,
  BsIcons,
  BoxIcons,
  OctIcons,
  MediaInfoCard,
  CommunitySection,
  ProSectionContainer,
  ProCard,
  React
};