import { SectionContainer, ProSectionContainer, CommunitySection, ProCard, FluentIcons, BoxIcons, OctIcons, BsIcons, GradientBanner, Link } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProSectionContainer,
  CommunitySection,
  ProCard,
  FluentIcons,
  BoxIcons,
  OctIcons,
  BsIcons,
  GradientBanner,
  Link,
  React
};