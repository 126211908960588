import axios from 'axios';

export const createProspects = async (body: { [key: string]: any }) => {

   const headers = {
      "authuserid": "1a76e865-8e10-45df-949c-2793902cd8c1",
      "authuseremail": "admin@unizo.io",
      "apikey": "KjZ_lYvdGQn9kQohuyAcOJQ0q0z04UXo",
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json"
   }

   return await axios('https://api.unizo.io/www/v1/prospects', { method: 'post', data: body, headers })
}