import React, { HTMLProps, useMemo, useState } from 'react';

import FAQs from './faq.js';
import clsx from 'clsx';
import { Minus, Plus } from '@styled-icons/boxicons-regular';
// import { paramCase } from 'text-param-case';
// import ReactMarkdown from 'react-markdown';
import { SectionContainer } from '../common/sectionContainer';

type PricingFaqProps = {
   filterBy?: string[] | string
}

const FILTER_BY_ALL = 'all';

export const Faq = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & PricingFaqProps>((props, ref) => {

   const { filterBy = [] } = props;
   const [activeFAQ, setActiveFAQ] = useState('');

   const filteredFAQs: any = useMemo(() => {

      if (typeof filterBy === 'string') {
         if (filterBy === FILTER_BY_ALL) {
            return FAQs
         }
         return (FAQs as { [key: string]: any }[]).filter(({ tags }) => (
            tags.includes(filterBy)
         ));
      }

      if (filterBy.includes(FILTER_BY_ALL)) return FAQs;

      return Array.isArray(filterBy) && (
         (FAQs as { [key: string]: any }[]).filter(({ tags }) => (
            filterBy.find((i) => tags.includes(i)) ? true : false
         ))
      );

   }, [filterBy]);

   return (
      <div
         className='layout mb-5'
         {...props}
         ref={ref}
      >
         <SectionContainer title={'Frequently Asked Questions'} rootClassName='!pb-2' />
         <div className='flex w-full flex-col gap-3'>
            {filteredFAQs.map((faq, index) => {
               return (
                  <Accordion
                     title={faq.question}
                     key={faq.question}
                     open={index === activeFAQ}
                     onOpen={() => {
                        setActiveFAQ(index);
                     }}
                     onClose={() => {
                        setActiveFAQ('');
                     }}
                  >
                     <p className='text-sm leading-7 text-gray-800'>{faq.answer}</p>
                  </Accordion>
               )
            })}
         </div>
      </div>
   )
});

function Accordion({ title, children, open, onOpen, onClose }) {
   const headingId = title;
   const panelId = headingId + '-panel';

   const handleOpen = () => {
      if (!open) {
         onOpen();
         history.pushState({}, '', '#' + headingId);
      } else {
         onClose();
         history.pushState({}, '', '');
      }
   };

   return (
      <div
         id={'parent-' + headingId}
         className={clsx(
            'dyte-accordion cursor-pointer border-0 border-solid last-of-type:border-0',
            open
               ? 'mb-4 rounded-2xl bg-secondary-800'
               : 'border-b border-gray-300 dark:border-zinc-700'
         )}
         role="tab"
         aria-expanded={open}
         aria-controls={panelId}
      >
         {/* Summary */}
         <div
            role="heading"
            className={clsx(
               'flex w-full cursor-pointer select-none items-center justify-between gap-4 border-0 border-solid bg-transparent p-6',
               open && '!pb-0 text-primary dark:text-primary-100'
            )}
            tabIndex={0}
            onClick={handleOpen}
            onKeyDown={(e) => {
               if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  handleOpen();
               }
            }}
            id={headingId}
         >
            <h3 id={headingId} className="text-lg font-semibold">
               {title}
            </h3>
            <div className="text-gray-500">

               {open ? (
                  <Minus
                     className={clsx(
                        'h-4 w-4 text-zinc-500 dark:text-zinc-300',
                     )}
                  />
               ) : (
                  <Plus
                     className={clsx(
                        'h-4 w-4 text-primary-100',
                     )}
                  />
               )}

            </div>
         </div>

         {/* Contents */}
         <div
            role="region"
            id={panelId}
            aria-labelledby={headingId}
            className={clsx(
               'accordion-content p-6 mt-5 !pt-0',
               open ? 'block' : 'hidden'
            )}
         >
            {children}
         </div>
      </div>
   );
}
