'use client';

import * as React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { cn } from '../../../../utils/common';


type ScrollbarProps = ScrollAreaPrimitive.ScrollAreaScrollbarProps;

type CustomProps = {
  rootClassName?: string
  rootStyle?: React.CSSProperties
};
const ScrollArea = React.forwardRef<
  React.ElementRef<ScrollbarProps & typeof ScrollAreaPrimitive.Root> & { viewPortRef?: any } & CustomProps,
  React.ComponentPropsWithoutRef<ScrollbarProps & typeof ScrollAreaPrimitive.Root> & { viewPortRef?: any } & CustomProps
>(({ className, children, rootStyle, viewPortRef, rootClassName, type, ...props }, ref) => (
  <ScrollAreaPrimitive.Root
    ref={ref}
    className={cn('relative overflow-hidden w-fit', rootClassName)}
    type={type}
    style={rootStyle}
  >
    <ScrollAreaPrimitive.Viewport ref={viewPortRef} className={cn('h-full w-full rounded-[inherit]', className)} {...props}>
      {children}
    </ScrollAreaPrimitive.Viewport>
    <ScrollBar asChild orientation={'vertical'} ></ScrollBar>
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' &&
      'h-full w-2.5 border-l border-l-transparent p-[1px]',
      orientation === 'horizontal' &&
      'h-2.5 flex-col border-t border-t-transparent p-[1px]',
      className
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className='relative flex-1 rounded-[.5rem] bg-gray-200' />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
