import { SectionContainer, ProSectionContainer, CardOrientationContainer, MediaInfoCard, Link, GradientBanner, ProCard, BsIcons, DataCard, CommunitySection } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProSectionContainer,
  CardOrientationContainer,
  MediaInfoCard,
  Link,
  GradientBanner,
  ProCard,
  BsIcons,
  DataCard,
  CommunitySection,
  React
};