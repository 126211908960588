import { SectionContainer, ProviderRespViewer, ProSectionContainer, MediaInfoCard, Link, CommunitySection, FluentIcons, BsIcons, BoxIcons, ProCard, CaseStudies, FancyContainer, Faq, GradientBanner, TalkingPoints } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProviderRespViewer,
  ProSectionContainer,
  MediaInfoCard,
  Link,
  CommunitySection,
  FluentIcons,
  BsIcons,
  BoxIcons,
  ProCard,
  CaseStudies,
  FancyContainer,
  Faq,
  GradientBanner,
  TalkingPoints,
  React
};