import { cva } from "class-variance-authority";
import React, { HTMLProps, useMemo } from "react";


import { MediaInfoCard } from '../../components/common/mediaInfoCard';
import { cn } from "../../utils/common";
import { SectionContainer } from "./sectionContainer";
import { Tick } from "./ProCard";
import { ProSectionContainer } from "./ProSectionContainer";


type DataCardProps = {
   title?: string
   description?: string
   sections?: SectionTypes[]

   titleProps?: HTMLProps<HTMLSpanElement>
   descriptionProps?: HTMLProps<HTMLSpanElement>
   sectionsRootProps?: HTMLProps<HTMLDivElement>

   inverse?: boolean
   image?: React.HTMLProps<HTMLImageElement>
   dark?: boolean
   bg: 'gray' | 'white'
};

type SectionTypes = {
   title?: string
   description?: string
   points: {
      label: string
   }[]
};

type PointsContainerProps = {
   section: SectionTypes
};

const rootCls = cva(
   cn('layout overflow-hidden')
)

const sectionsRootCls = cva(
   cn('grid grid-cols-1 xl:grid-cols-2 gap-10 mt-3')
)

const titleCls = cva(
   cn('text-xl font-semibold')
)
const descriptionCls = cva(
   cn(' text-md leading-7')
)

const PointsContainer = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & PointsContainerProps>((props, ref) => {

   const {
      className,
      section,
      ...rest
   } = props;

   const {
      title,
      description,
      points = []
   } = section;

   return (
      <div  className={cn('flex flex-col gap-5', className)} {...rest} ref={ref}>
         <div
            className="flex flex-col justify-start items-start"
         >
            {title && (
               <span
                  className={titleCls({ className: 'mb-2' })}
               >
                  {title}
               </span>
            )}
            {description && (
               <span
                  className={descriptionCls()}
               >
                  {description}
               </span>
            )}
         </div>
         <div
            className="flex flex-col"
         >
            {points.map((point, index) => (
               <div
                  className="flex flex-row gap-2"
                  key={index}
               >
                  <Tick />
                  <p className="">{point?.label}</p>
               </div>
            ))}
         </div>
      </div>
   )
})

const defaultSections = [
   {
      title: ' ',
      description: ' ',
      points: [
         {
            label: '',
         },
      ]
   },
]

export const DataCard = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & DataCardProps>((props, ref) => {

   const {
      className,
      title,
      description,
      sections = defaultSections,
      titleProps,
      descriptionProps: descriptionPropsProp,
      sectionsRootProps,
      inverse = false,
      image,
      dark = false,
      bg = 'white',
      ...rest
   } = props;

   const descriptionProps = useMemo(() => {
      return {
         className: 'text-md !font-medium',
         ...descriptionPropsProp
      };
   }, [descriptionPropsProp])

   return (
      <div
         className={rootCls({ className })}
         {...rest}
         ref={ref}
         data-aos='zoom-in'
      >
         <MediaInfoCard
            image={{
               ...image,
            } as any}
            inverse={!inverse}
            bg={bg}
            dark={dark}
            className="lg:gap-[6rem]"
         >
            <div >
               <ProSectionContainer
                  align="start"
                  title={title}
                  dark={dark}
                  bg={bg}
                  description={description}
                  titleProps={titleProps as any}
                  descriptionProps={descriptionProps as any}
                  sectionContainerClassName="!py-2 !px-0"
                  className="!m-[0px] !py-0"
               />
               <div
                  {...sectionsRootProps}
                  className={sectionsRootCls({
                     className: sectionsRootProps?.className
                  })}
               >
                  {sections.map((section, index: number) => (
                     <PointsContainer section={section} key={index} />
                  ))}
               </div>
            </div>
         </MediaInfoCard>
      </div>
   )
})