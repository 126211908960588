import { SectionContainer, CTA, ProviderRespViewer, Link, ProSectionContainer, CommunitySection, BsIcons, FluentIcons, OctIcons } from "../../../../docs/partials/index";
import { Github } from "@styled-icons/bootstrap";
import { Gitlab } from "@styled-icons/boxicons-logos";
import * as React from 'react';
export default {
  SectionContainer,
  CTA,
  ProviderRespViewer,
  Link,
  ProSectionContainer,
  CommunitySection,
  BsIcons,
  FluentIcons,
  OctIcons,
  Github,
  Gitlab,
  React
};