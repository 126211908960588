import { CTA, SectionContainer } from "../../../../docs/partials/index";
import { Button, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import * as React from 'react';
export default {
  CTA,
  SectionContainer,
  Button,
  Result,
  SmileOutlined,
  React
};