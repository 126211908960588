import { SectionContainer, ProviderRespViewer, Link, ProSectionContainer, ProCard, BoxIcons, OctIcons, FluentIcons, BsIcons, CTA, CommunitySection } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProviderRespViewer,
  Link,
  ProSectionContainer,
  ProCard,
  BoxIcons,
  OctIcons,
  FluentIcons,
  BsIcons,
  CTA,
  CommunitySection,
  React
};