import React, { cloneElement } from 'react';
import { GraphUp, Gear, ShieldCheck, BarChartLine } from '@styled-icons/bootstrap'
import { MoneyWithdraw } from '@styled-icons/boxicons-regular';
import { ArrowTrendingSettings } from '@styled-icons/fluentui-system-regular';

const features = [
   {
      title: 'Accelerated Go-To Market',
      description: 'Considerably decrease the time and effort needed to develop and test frameworks, expediting the delivery of new features and integrations.',
      icon: <GraphUp />, // Replace with actual icon or icon component if you have one
   },
   {
      title: 'Cost Reduction',
      description: 'Diminish development and maintenance costs by reducing the necessity for specialized coding skills and manual testing efforts.',
      icon: <MoneyWithdraw />, // Replace with actual icon
   },
   {
      title: 'Reliability',
      description: 'In-house expertise ensures high-quality integrations through automated testing and robust designs which minimize errors and optimize performance.',
      icon: <ArrowTrendingSettings />, // Replace with actual icon
   },
   {
      title: 'Scalability',
      description: `With top-tier product engineering expertise, build, launch, and manage integrations at scale.`,
      icon: <BarChartLine />, // Replace with actual icon
      className: 'col-start-2 col-span-1'
   },
   {
      title: 'Enterprise Grade Security',
      description: `Guarantee top-tier enterprise-class security integrations using automated and strong design tools that reduce risks and boost customer confidence.`,
      icon: <ShieldCheck />, // Replace with actual icon,
      className: 'col-start-3 col-span-1',
   },
];

export const TalkingPoints = () => {
   return (
      <div className='services-talking-points'>
         <div className=" py-16 px-6 md:px-20 lg:px-32 layout">
            <h2 className="container-title ">
               Why Unizo?
            </h2>
            <div className="services-talking-points-list gap-5 justify-items-center" >
               {features.map(({ className, ...feature }: Record<string, any>, index) => (
                  <div key={index} className={`bg-white p-6 text-center  ` + className} style={{ maxWidth: '18rem' }}>
                     <div className='flex flex-row justify-center'>
                        <div className='featuredIcon mb-4 flex-shrink-0'>
                           {feature.icon ? cloneElement(feature.icon, {
                              className: "h-6 w-6",
                           }) : null}
                        </div>
                     </div>
                     <h3 className="text-xl text-gray-800 mb-3">
                        {feature.title}
                     </h3>
                     <p className="text-gray-600 font-normal">{feature.description}</p>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};
