import React, { useEffect, useMemo, useState } from "react";

export default function useCaptcha() {
   const [isVerified, serVerified] = useState<boolean>(false);

   useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      window.onReCaptcha = (token) => {
         if (token) {
            serVerified(true);
         }
      };

      return () => {
         document.body.removeChild(script);
      };
   }, []);

   const elem = <div
      className="g-recaptcha"
      data-sitekey="6Ldo66EoAAAAAObxMF2AFwa_IzdVrgfNLepixbl9"
      data-callback="onReCaptcha"
   />

   return {
      isVerified,
      element: elem
   };
}