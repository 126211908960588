import { SectionContainer, ProSectionContainer, Link, GradientBanner, BsIcons, ProCard, MediaInfoCard, CommunitySection, DataCard } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProSectionContainer,
  Link,
  GradientBanner,
  BsIcons,
  ProCard,
  MediaInfoCard,
  CommunitySection,
  DataCard,
  React
};