import clsX, { ClassValue } from 'clsx';

import { twMerge } from 'tailwind-merge';

type ClsMergeTypes = {
   className?: string
}

export const clsMerge = (className: string) => {

   return function (sbArg?: ClsMergeTypes) {
      const classNameArr = [className];
      sbArg?.className && classNameArr.push(sbArg.className);
      return classNameArr.join(' ');
   }
}

export function cn(...inputs: ClassValue[]) {
   return twMerge(clsX(inputs));
}

export const docsLink = 'https://docs.unizo.io';