import { SectionContainer, CommunitySection, ProSectionContainer, ProCard, BsIcons, FluentIcons, OctIcons, BoxIcons, Link } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  CommunitySection,
  ProSectionContainer,
  ProCard,
  BsIcons,
  FluentIcons,
  OctIcons,
  BoxIcons,
  Link,
  React
};