import { SectionContainer, ProviderRespViewer, Link, ProSectionContainer, ProCard, BsIcons, OctIcons, FluentIcons, CommunitySection, CTA } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProviderRespViewer,
  Link,
  ProSectionContainer,
  ProCard,
  BsIcons,
  OctIcons,
  FluentIcons,
  CommunitySection,
  CTA,
  React
};